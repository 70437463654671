@import "../../../styles/variables";

.direction-list-info {
  font-family: $default-font-family;
  .direction-list-info__title {
    font-size: 0.8em;
    font-weight: bold;
  }

  .direction-list-info__name, .direction-list-info__vertices-item {
    display: block;
    font-size: 1.1em;
  }
}