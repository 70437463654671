.multi-input-row{
  width: 100%;
  display: flex;
  justify-content: space-between;

  .multi-input-row__field{  
    flex: 5
  }

  .multi-input-row__button{
    flex: 1
  }
}