.news-list-item {
  position: relative;
  padding-bottom: 3px;
  border-bottom: 1px solid #c8c8c8;

  .news-list-item__image {
    margin: 7px 0 7px 0;
    width: 200px;
    height: 200px;
    display: block;
    background: url("/images/placeholder_200.png");
    // border: 1px solid #c8c8c8;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }

  .news-list-item__detail-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
